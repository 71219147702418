/*
Documentation

This file holds the database calls and error logs for this system

*/

import Axios from 'axios';
import keys from '../keys'
import store from 'store';

export const architeckCall = async (settings) => {
    return new Promise(async(resolve) => {

        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;

        if(!data) { data = {} }

        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })


            resolve(result.data)

        } catch(e) {
            console.log(e)
            resolve({success: false, message: 'architeckCall failed at url: ' + url + '. Error: ' + e.toString()})
        }

    })
}

export const log = async (payload) => {

    const device = store.getState().device.info;

    payload.device = {
        type    : device.device,
        mobile  : device.isMobile,
        width   : device.currentScreenWidth,
        height  : device.currentScreenHeight,
    }

    //log must not be a standard call to prevent infinite loop if all requests fail
    //we will only fire off a console.log if we are in development and the log fails
    try {
        const log = await Axios({
            method: 'post',
            url: keys.API_URL + '/api/v1/logs/create',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
            data: {...payload}
        })

        if(!log.data.success && window.location.hostname === 'localhost') {
            console.log('log failed 1')
            console.log(log)
        }


    } catch(e) {

        if(window.location.hostname === 'localhost') {
            console.log('log failed 2')
            console.log(e)
        }

    }

}
